import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import PageWrapper from '../components/PageWrapper'

import pablo from '../assets/image/headshots/pablo.webp'
import ramon from '../assets/image/headshots/ramon.webp'
import jaime from '../assets/image/headshots/jaime.webp'

const AboutPage = () => {
  return (
    <PageWrapper
      headerConfig={{
        align: 'right',
        button: 'null', // cta, account, null
      }}
      footerConfig={{
        style: 'style1',
      }}
      seoConfig={{
        title: 'About us',
      }}
    >
      <div className="inner-banner pt-24 pb-md-11 bg-default-2">
        <Container>
          <Row className="justify-content-center pt-5">
            <Col lg="9" xl="8">
              <div className="px-md-15 text-center">
                <h2 className="title gr-text-2 mb-9">Meet our team</h2>
                <p className="gr-text-8 mb-13">
                  Our team is composed of a group of entrepreneurs that love
                  what we do. Whether developing clean code, expertly crafting
                  designs or providing top-notch customer experiences we strive
                  to believe in the products we build.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="contact-section pb-5 pb-md-29 bg-default-2 ">
        <Container>
          <Row className="justify-content-left mb-lg-n15 mb-0">
            <Col lg="3" md="4" xs="6" className="mb-lg-15 mb-13">
              <div className="team-card">
                <div className="card-image">
                  <img
                    src={pablo}
                    alt="pablo rocha"
                    className="w-100 img-thumbnail"
                    style={{ height: 340, objectFit: 'cover' }}
                  />
                </div>
                <div className="card-text pt-9">
                  <h3 className="gr-text-7 mb-2">Pablo Rocha</h3>
                  <p className="gr-text-9 mb-0 line-height-1">Lead Developer</p>
                </div>
              </div>
            </Col>
            <Col lg="3" md="4" xs="6" className="mb-lg-15 mb-13">
              <div className="team-card">
                <div className="card-image">
                  <img
                    src={ramon}
                    alt="ramon almaguer"
                    className="w-100 img-thumbnail"
                    style={{ height: 340, objectFit: 'cover' }}
                  />
                </div>
                <div className="card-text pt-9">
                  <h3 className="gr-text-7 mb-2">Ramon Almaguer</h3>
                  <p className="gr-text-9 mb-0 line-height-1">
                    Expert Salesman
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="3" md="4" xs="6" className="mb-lg-15 mb-13">
              <div className="team-card">
                <div className="card-image">
                  <img
                    src={jaime}
                    alt="arturo jaime garcia"
                    className="w-100 img-thumbnail"
                    style={{ height: 340, objectFit: 'cover' }}
                  />
                </div>
                <div className="card-text pt-9">
                  <h3 className="gr-text-7 mb-2">Arturo Jaime Garcia</h3>
                  <p className="gr-text-9 mb-0 line-height-1">Coding Machine</p>
                </div>
              </div>
            </Col>
            <Col
              lg="3"
              md="6"
              xs="6"
              className="mb-13 d-flex align-items-center mt-md-24 mt-lg-0"
            >
              <div className="mt-xs-n25">
                <h3 className="gr-text-6">Interested in joining our team ?</h3>
                <a
                  href="/contact"
                  className="btn-link with-icon gr-text-blue gr-text-7 font-weight-bold mt-5"
                >
                  Apply now&nbsp;
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </PageWrapper>
  )
}
export default AboutPage
